@font-face {
  font-family: Bebas-Light;
  src: url(fonts/bebasneuepro-middle.ttf);
}

@font-face {
  font-family: Bebas-Bold;
  src: url(fonts/bebasneuepro-bold.ttf);
}

@font-face {
  font-family: Rollerscript-Smooth;
  src: url(fonts/rollerscript-smooth.ttf);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

p, span, h1, h2, h3, h4, h5, input, button { 
  font-family: Bebas-Bold;
}

.App {
  text-align: center;
}

.no-margin {
  margin: 0 !important;
}

/* NAVBAR */

nav {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0px;
  z-index: 10;
  border-bottom: 2px;
  height: 8%;
}

.hideNav {
  display: none;
}

nav ul {
  text-align: center;
  list-style-type: none;
  list-style: none;
  visibility: hidden;
  opacity: 0;
  transition:visibility 0.3s linear,opacity 0.3s linear;
  padding-left: 0;
}

nav:hover ul {
  visibility: visible;
  opacity: 1;
}

nav ul li {
  display: inline-block;
  list-style-type: none;
  list-style: none;
}

nav ul li a {
  display: block;
  padding: 0 15px;
  text-decoration: none;
  color: #ebebeb;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
  font-family: Bebas-Bold;
  font-size: 30px;
  color: rgb(0, 0, 0);
  -webkit-text-stroke: 1px black;
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all .5s;
}

nav ul li a:hover {
  color: #000000;
}

nav ul li a {
  position: relative;
}

nav ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  color: transparent;
  background: #333;
  height: 1px;
}

/* HOME PAGE */

.bankInfo {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes full height of the viewport */
}

.bankInfo-content {
  display: flex;
  flex: 1;
}

.bankInfo-top {
  display: flex;
  width: 100%;
  background-color: rgb(215,128,23);
  text-align: center; 
  color: white;
  font-size: 4.5vh;
  padding: 0.2em 0;
}

.bankInfo-top h3 {
  margin: 0;
  width: 100%; 
}

.bankInfo-left {
  width: 50%;
  padding-left: 7.5%;
  margin-left: 3%;
  margin-right: 3%;
  text-align: left;
  position: relative;
  padding-top: 4%;
}

.bankInfo-right {
  width: 50%;
  padding-left: 7.5%;
  margin-left: 3%;
  margin-right: 3%;
  text-align: left;
  position: relative;
  padding-top: 4%;
}


.bankInfo-right {
  padding-left: 0%;
}

.bankInfo-titleWithArrow {
  display: flex;
  align-items: center;
}

.bankInfo-titleWithArrow > h3 {
  margin: 0;
  position: relative;
}

.strzalka {
  position: absolute;
  width: 30vh;
}

.strzalka1 {
  transform: scale(0.6) translateX(40vh) translateY(-4vh);
}

.strzalka2 {
  transform: scale(0.6) translateX(56vh) translateY(-4vh);
  /*transform: rotate(100deg) translateX(7vh) translateY(-25vh);
  transform: rotate(100deg) translateX(10vh) translateY(-9vh);
  left: 32vh;*/
}

.strzalka3 {
  transform: scale(0.6) translateX(46vh) translateY(-4vh);
  /*transform: rotate(95deg) translateX(8vh) translateY(-23vh);*/
}

.strzalka4 {
  transform: rotate(20deg) scale(0.6) translateX(74vh) translateY(-28vh);
}

.bankInfo-left h3,
.bankInfo-right h3 {
  font-size: 4.5vh;
  margin-bottom: 0px;
  letter-spacing: 0.2vh;
}

.bankInfo-przedmiotMiesiaca {
  margin-top: 0.1em !important;
  line-height: 0.9em;
}

.bankInfo-left h2,
.bankInfo-right h2 {
  font-size: 13vh;
  color:rgb(215,128,23);
  margin-top: 0px;
  letter-spacing: 0.5vh;
  margin-bottom: 0.5em;
}

.bankInfo-right-product-line {
  display: flex;
  align-items: center;
}

.bankInfo-right-product-line img {
  width: 20vh;
  margin-left: -7.5%;
}

.bankInfo-right-product-line h2 {
  margin: 0;
  margin-left: 3%;
}

.bankInfo-right-product-line h3 {
  margin-left: 2vh; 
}

.bankInfo-sumOfGifts {
  padding-top: 1vh;
}

.bankInfo-footer {
  display: flex;
  width: 100%;
  background-color: rgb(215,128,23); /* Optional background color for visual separation */
  text-align: left; /* Optional text alignment */
  color: white;
  font-size: 4.5vh;
  padding: 0.2em 0;
}


.bankInfo-footer h3 {
  padding-left: 35%;
  margin: 0;
  border-right: 4px solid white;
}

.bankInfo-footer-title {
  width: 30%;
}

.bankInfo-footer-sponsors {
  display: block;
  position: relative;
  overflow: hidden;
  width: 70%;
}

.bankInfo-footer-sponsors h3.sponsor {
  padding-left: 0%;
  text-align: center;
}

.sponsor {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 5s ease-in-out;
}

.sponsor.visible {
  opacity: 1;
}

.sponsor.hidden {
  opacity: 0;
}

/* ADD / EDIT DONATION PAGE */

.addDonationPage,
.addWithdrawalPage,
.editDonationPage,
.loginPage {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 100px;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: auto;
  padding: 20px;
  border: 7px solid rgb(255, 255, 255);
  border-radius: 25px;
  font-family: Bebas-Bold;
  backdrop-filter: blur(25px);
  background-color: rgba(85, 80, 15, 0.2);
}

.editDonationPage .formContainer {
  border: 5px solid lightsalmon;
}

.formContainer .formInput {
  height: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 15px;
  font-size: 4rem;
  text-align: center;
  font-family: Bebas-Light;
}

.formContainer span, label {
  font-size: 3rem;
}

.formContainer button {
  border: none;
  background-color: rgb(215,128,23);
  border: 1px black;
  border-radius: 5px;
  color: rgb(27, 26, 26);
  font-weight: bold;
  font-size: 5rem;
  padding: 30px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.radioGroup {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
}

#productRadioButton1 {
  background-image: url('./assets/ice-cream.png');
  background-size: cover;
  background-position: center center;
}

#productRadioButton2 {
  background-image: url('./assets/sandwich.jpg');
  background-size: cover;
  background-position: center center;
}

#productRadioButton3 {
  background-image: url('./assets/waffle.jpg');
  background-size: cover;
  background-position: center center;
}

.ageRangeRadioButton {
  width: 27%;
  border-radius: 10%;
  padding: 10px;
  border: 5px solid black;
  cursor: pointer;
}

  .productRadioButton {
    width: 27%;
    border-radius: 10%;
    padding: 10px;
    border: 5px solid black;
    cursor: pointer;
    filter: grayscale(80%);
  }

  .donationTypeRadioButton {
    width: 40%;
    border-radius: 10%;
    padding: 10px;
    border: 5px solid black;
    cursor: pointer;
  }

.ageRangeRadioButton input {
  display: none;
}

  .productRadioButton input {
    display: none;
  }
  
  .donationTypeRadioButton input {
    display: none;
  }

.ageRangeRadioButton > input + label {
  font-size: 2rem;
  display: block;
}

  .productRadioButton > input + label {
    font-size: 2rem;
    display: block;
    padding-bottom: 100%;
  }

.ageRangeRadioButton:has(input:checked + label) {
  background-color: rgb(215,128,23);
  transition: background-color 300ms linear;
}

  .donationTypeRadioButton:has(input:checked + label) {
    background-color: rgb(215,128,23);
    transition: background-color 300ms linear;
  }

  .productRadioButton:has(input:checked + label) {
    filter: grayscale(0%) !important; 
    transform: translate(4px, 4px); 
    box-shadow: inset 3px 3px 3px black;
  }

.editDonationPage .formContainer button {
  color: rgb(215,128,23);
}

.formContainer button:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(215,128,23);
}

span {
  color: red;
}

.MuiBox-root div {
  font-family: Bebas-Bold;
  font-size: 2vh;
}

/* ANIMATION CONTAINER */

.container {
  position: relative;
}

.animation-container {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;

  padding: .8em 1.2em;
  color: white;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#gimmick {
  display: block;
  pointer-events:none;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 6;
}

.coinRain {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 7;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  filter: blur(0px)
}

.coinRainOpacity {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
}

@keyframes blur-in {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(10px);
  }
}

.coinRain {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50% , -50%);
  display: block;
}

.coinRain-start {
  font-family: Bebas-Bold;
  font-size: 10vh;
  margin-bottom: 0;
  color: white;
}

.coinRain-start-donation {
  margin-top: 20vh;
}

.coinRain-middle {
  font-family: Bebas-Bold;
  font-size: 28vh;
  color: rgb(215,128,23);
  margin-top: 0;
  margin-bottom: 0;
  animation: pulse 2s infinite;
}

.coinRain-img {
  width: 35vh;
  margin: 0 auto;
}

.coinRain-end {
  font-family: Rollerscript-Smooth;
  font-size: 12vh;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

@keyframes pulse {
	0% {
    transform: scale(1.15);
	}

	70% {
    transform: scale(1);
	}

	100% {
    transform: scale(1.15);
	}
}

/* BUBBLES */

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  display: block;
  pointer-events: none;
}

.bubble {
  position: absolute;
  bottom: -35vh;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Bebas-Bold', sans-serif;
  color: rgb(0, 0, 0);
  -webkit-text-stroke: 0.5px black;
  text-align: center;
  transition: width 0.3s, height 0.3s;
}

.donationAmount {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.bubble p {
  margin: 0;
  /*font-size: 1vw; /* Use vw for responsive font size */
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
  font-family: 'Bebas-Bold';
}

/*.bubble div {
  font-size: 6cqh;
}*/

.bubble:nth-child(1) {
  width: 33vh;
  height: 33vh;
  left: 10%;
  font-size: 4.5cqh;
  animation-duration: 20s;
  background-image: url("assets/bąbelki_czerwony.svg");
  background-size: cover;
  background-position: center;
  animation: rise 30s infinite ease-in;
}

.bubble:nth-child(2) {
  width: 42vh;
  height: 42vh;
  font-size: 6cqh;
  left: 50%;
  animation-duration: 24s;
  animation-delay: 2s;
  background-image: url("assets/bąbelki_żółty.svg");
  background-size: cover;
  background-position: center;
  animation: rise2 20s infinite ease-in;
}

.bubble:nth-child(3) {
  width: 28vh;
  height: 28vh;
  font-size: 4cqh;
  left: 70%;
  animation-duration: 28s;
  animation-delay: 4s;
  background-image: url("assets/bąbelki_zielony.svg");
  background-size: cover;
  background-position: center;
  animation: rise3 28s infinite ease-in;
}

.bubble:nth-child(4) {
  width: 40vh;
  height: 40vh;
  font-size: 6cqh;
  left: 20%;
  animation-duration: 32s;
  animation-delay: 8s;
  background-image: url("assets/bąbelki_pomarańczowy.svg");
  background-size: cover;
  background-position: center;
  animation: rise 42s infinite ease-in;
}

.bubble:nth-child(5) {
  width: 32vh;
  height: 32vh;
  font-size: 4.5cqh;
  left: 55%;
  animation-duration: 36s;
  animation-delay: 0s;
  background-image: url("assets/bąbelki_fioletowy.svg");
  background-size: cover;
  background-position: center;
  animation: rise 24s infinite ease-in;
}

.bubble:nth-child(6) {
  width: 30vh;
  height: 30vh;
  font-size: 4.5cqh;
  left: 35%;
  animation-duration: 38s;
  animation-delay: 7s;
  background-image: url("assets/bąbelki_czerwony.svg");
  background-size: cover;
  background-position: center;
  animation: rise2 32s infinite ease-in;
}

.bubble:nth-child(7) {
  width: 27vh;
  height: 27vh;
  font-size: 4cqh;
  left: 85%;
  animation-duration: 42s;
  animation-delay: 11s;
  background-image: url("assets/bąbelki_żółty.svg");
  background-size: cover;
  background-position: center;
  animation: rise 48s infinite ease-in;
}

.bubble:nth-child(8) {
  width: 36vh;
  height: 36vh;
  font-size: 5cqh;
  left: 77%;
  animation-duration: 46s;
  animation-delay: 1s;
  background-image: url("assets/bąbelki_zielony.svg");
  background-size: cover;
  background-position: center;
  animation: rise 24s infinite ease-in;
}

.bubble:nth-child(9) {
  width: 38vh;
  height: 38vh;
  font-size: 5cqh;
  left: 65%;
  animation-duration: 26;
  animation-delay: 5s;
  background-image: url("assets/bąbelki_pomarańczowy.svg");
  background-size: cover;
  background-position: center;
  animation: rise2 20s infinite ease-in;
}

.bubble:nth-child(10) {
  width: 31vh;
  height: 31vh;
  font-size: 4.5cqh;
  left: 25%;
  animation-duration: 36s;
  animation-delay: 9s;
  background-image: url("assets/bąbelki_fioletowy.svg");
  background-size: cover;
  background-position: center;
  animation: rise3 40s infinite ease-in;
}

.bubble:nth-child(11) {
  width: 37vh;
  height: 37vh;
  left: 30%;
  animation-duration: 22s;
  animation-delay: 0s;
  background-image: url("assets/bąbelki_czerwony.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(12) {
  width: 25vh;
  height: 25vh;
  left: 40%;
  animation-duration: 28s;
  animation-delay: 6s;
  background-image: url("assets/bąbelki_żółty.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(13) {
  width: 32vh;
  height: 32vh;
  left: 75%;
  animation-duration: 32s;
  animation-delay: 4.5s;
  background-image: url("assets/bąbelki_zielony.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(14) {
  width: 40vh;
  height: 40vh;
  left: 10%;
  animation-duration: 14s;
  animation-delay: 2.5s;
  background-image: url("assets/bąbelki_pomarańczowy.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(15) {
  width: 24vh;
  height: 24vh;
  left: 45%;
  animation-duration: 16s;
  animation-delay: 5s;
  background-image: url("assets/bąbelki_fioletowy.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(16) {
  width: 42vh;
  height: 42vh;
  left: 35%;
  animation-duration: 22s;
  animation-delay: 3.5s;
  background-image: url("assets/bąbelki_czerwony.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(17) {
  width: 35vh;
  height: 35vh;
  left: 65%;
  animation-duration: 46s;
  animation-delay: 2s;
  background-image: url("assets/bąbelki_żółty.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(18) {
  width: 35vh;
  height: 35vh;
  left: 49%;
  animation-duration: 26s;
  animation-delay: 4s;
  background-image: url("assets/bąbelki_zielony.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(19) {
  width: 27vh;
  height: 27vh;
  left: 10%;
  animation-duration: 18s;
  animation-delay: 5s;
  background-image: url("assets/bąbelki_pomarańczowy.svg");
  background-size: cover;
  background-position: center;
}

.bubble:nth-child(20) {
  width: 29vh;
  height: 29vh;
  left: 80%;
  animation-duration: 48s;
  animation-delay: 1s;
  background-image: url("assets/bąbelki_fioletowy.svg");
  background-size: cover;
  background-position: center;
}


@keyframes rise {
  0% {
    bottom: -42vh;
    transform: translateX(0);
  }

  50% {
    transform: translateX(10vh);
  }

  100% {
    bottom: 100vh;
    transform: translateX(-20vh);
  }
}

@keyframes rise2 {
  0% {
    bottom: -42vh;
    transform: translateX(0);
  }

  50% {
    transform: translateX(-18vh);
  }

  100% {
    bottom: 100vh;
    transform: translateX(15vh);
  }
}

@keyframes rise3 {
  0% {
    bottom: -42vh;
    transform: translateX(0);
  }

  50% {
    transform: translateX(3vh);
  }

  100% {
    bottom: 100vh;
    transform: translateX(-8vh);
  }
}

/* ADMIN PANEL */

.adminPanel {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 15%;
}

.adminPanel > .adminPanelButton {
  margin-top: 2%;
  width: 80%;
  height: 100%;
  padding: 10vh 0;
  border: 6px solid black;
  border-radius: 5vh;
  font-weight: 1000;
  font-size: 2rem;
  background-color: white;
  color: rgb(215,128,23);
}

.adminPanel > .adminPanelButton:hover {
  background-color: rgb(215,128,23);
  color: white;
}

.adminPanelButton > a {
  display: block;
  height: 100%;
  width: 100%;
}

.additionalAdminPanel {
  position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 0px;
    height: 90%;
    background-color: gray;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border-top: 6px solid black;
}

.additionalAdminPanel > .additionalAdminPanelButton {
  width: 80%;
  border: 6px solid black;
  border-radius: 5vh;
  font-weight: 1000;
  font-size: 2rem;
  background-color: white;
  color: rgb(215,128,23);
}

.additionalAdminPanelToggle {
  position: absolute;
  bottom: 0;
  padding: 1em 8em;
  border-radius: 45% 45% 0 0;
  font-weight: 1000;
  font-size: 2rem;
  background-color: gray;
  color: white;
  
  -webkit-transition: bottom 0.5s;
  transition: bottom 0.5s;
  
  border: 6px solid black;
}

.additionalAdminPanelToggle:hover {
  background-color: gray;
  color: white;
}

.additionalAdminPanelToggle.activePanel {
  bottom: 90%;
}

.additionalAdminPanelToggle.activePanel + .additionalAdminPanel {
  max-height: 100%;
}